import {LANDSPRACHETODOMAINMAP} from "~/init/LANDSPRACHETODOMAINMAP";


export async function redirectToCanonicalURL(language: string, land: string, shopname:string) {
    if (typeof (LANDSPRACHETODOMAINMAP[land.toLowerCase()][language.toLowerCase()]) === "undefined") {
        return false;
    }
    const link = getCanonicalURL(language, land, shopname);
    const domain = import.meta.env.PROD ? (LANDSPRACHETODOMAINMAP[land.toLowerCase()][language.toLowerCase()]) : "localhost";
    const shopDIrectoryLocalized = getShopDirectoryLocalizedFromLanguage(language);
    const url = useRequestURL();
    const route = useRoute();
    if(domain === url.hostname && shopDIrectoryLocalized === route.params.shopdirectoryLocalized) {
        return false;
    }
    await navigateTo(link, {redirectCode:301, external:true})
    return true;
}

export function getCanonicalURL(language:string, land:string, shopname:string) {
    const domain = import.meta.env.PROD ? (LANDSPRACHETODOMAINMAP[land.toLowerCase()][language.toLowerCase()]) : "localhost";
    console.log("land:"+land+"; language:"+language);
    console.log("domain:"+domain);
    const domainWithprotocol = (import.meta.env.PROD ? "https://" : "http://")+domain;
    const shopDIrectoryLocalized = getShopDirectoryLocalizedFromLanguage(language);
    return domainWithprotocol + "/" + shopDIrectoryLocalized + "/" + shopname;
}

export async function redirectToThirdLevelDomain(url : string) {
    const link = import.meta.env.PROD ? "https://www."+url : "http://"+url;
    await navigateTo(link, {redirectCode:301, external:true})
    return true;
}

function getShopDirectoryLocalizedFromLanguage(language: string) {
    switch (language.toLowerCase()) {
        case "fr":
            return "boutique"
        case "en":
            return "store";
        case "es":
            return "tienda";
        case "no":
            return "butikk";
        default:
            return "shop";
    }
}
