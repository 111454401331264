<template>

  <div>
    <div class="team-wrapper" v-if="shopnameSet && (shopIsActive || shopOwner)">
      <ShopHeader
          :shopinfo="shopObj"
          :isOwner="shopOwner"
      />

<!--<ShopFilterMenue :isOwner="shopOwner"/>-->

      <main class="shop__content">
        <NuxtPage
            :shop="shopObj"
            :isOwner="shopOwner"
        />
      </main>

      <PopupContainer/>
      <ModuleShopPopupMessages/>

      <ShopFooter :isOwner="shopOwner"/>
    </div>
    <div class="team-wrapper" v-if="!shopnameSet || (!shopIsActive && !shopOwner)">
      <ShopNotAvailable/>
    </div>
  </div>
</template>

<script lang="ts">


import {EventListener, EventVerteiler} from "@k/global/eventVerteiler";
import "~/assets/scss/style.scss"
import {InterframeCommunicator} from "@k/global/frameCommunicator";
import "owayo_menu/dist/style.css"
import {UserManagementConnectorRoutes} from "@k/global/UserManagementConnectorRoutes";
import {useStore} from "vuex";
import {useShopInfos} from "~/store/shopInfosStore";
import {Navigation_opencomponentHandler_SHOP} from "~/navigation_opencomponentHandler_SHOP";
import {initI18n} from "~/init/init_i18n";
import {initEventListeners} from "~/init/initEventListeners";
import {initSession} from "~/init/initSession";
import {loadInitialItemsAndPages, loadInitialShopInfo} from "~/init/loadInitialData";
import {loadWebsitelinks} from "~/init/loadWebsitelinks";
import "vanilla-cookieconsent";
import {CookieBannerhandler} from "@k/global/cookieconsent/cookieConsent";
import {setBUILD_ID} from "@k/global/globalUtils";
import {redirectToCanonicalURL, redirectToThirdLevelDomain} from "~/init/canonicalURL";

export type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U;
};


function getLanguageFromShopPfad(shopdirectoryLocalized: string) {
  switch (shopdirectoryLocalized) {
    case "shop":
      return "de"
    case "boutique":
      return "fr"
    case "tienda":
      return "es"
    case "store":
    case "store_test":
      return "en"
    case "butikk":
      return "no";
    default:
      return "de";
  }
}




function domainOrShopDirectoryLocalizedDoesNotMatchShop(languageFromShopDirectoryLocalized: string) {
  const shopInfos = useShopInfos();
  let doesNotMatch = (shopInfos.getShopLand.toLowerCase() !== shopInfos.getDomainLand.toLowerCase()) || (shopInfos.getShopSprache.toLowerCase() !== languageFromShopDirectoryLocalized.toLowerCase());
  return doesNotMatch;
}

export default {
  name: 'App',
  setup: async function () {
    setBUILD_ID();
    const route = useRoute();
    console.log(route.params.shopdirectoryLocalized)
    if (route.params.shopname as undefined | string === undefined) {
      throw createError({
        statusCode: 404,
        message: 'no shopname specified',
        fatal: true
      })
    }

    const language = getLanguageFromShopPfad((route.params.shopdirectoryLocalized as string | undefined) || "shop");
    useHead({htmlAttrs: {lang: language}});
    const requestURL = useRequestURL();

    UserManagementConnectorRoutes.initLanguage(language);
    const vuexStore = useStore();
    const i18n = initI18n(language);
    const loggedIn = ref(false);
    const eventListener = new EventListener();
    onUnmounted(() => EventVerteiler.unsubscribe(eventListener));
    onMounted(function () {
      InterframeCommunicator.initWindowListener();
      CookieBannerhandler.init(i18n.t);
      initEventListeners(eventListener, vuexStore, loggedIn, i18n);
      initSession(vuexStore, i18n, loggedIn);
    });
    await loadInitialShopInfo();
    const shopInfos = useShopInfos();

    if (domainOrShopDirectoryLocalizedDoesNotMatchShop(language)) {
      await redirectToCanonicalURL(shopInfos.getShopSprache, shopInfos.getShopLand, shopInfos.shopname);
    }

    if (import.meta.env.PROD){
      if(!requestURL.host.startsWith('www.')){
        await redirectToThirdLevelDomain(requestURL.host+route.fullPath) ;
      }
    }

    await loadInitialItemsAndPages(shopInfos.shopname, language, shopInfos.getShopLand);

    await loadWebsitelinks(language);
    const shopInfoStore = useShopInfos();
    Navigation_opencomponentHandler_SHOP.setShopInfoStore(shopInfoStore);

    return {
      shopObj: computed(() => shopInfoStore.shopInfo),
      shopOwner: computed(() => shopInfoStore.isShopOwner),
      shopName: computed(() => shopInfoStore.shopname),
      shopIsActive: computed(() => shopInfoStore.isShopActive),
      username: computed(() => vuexStore.getters.getUsername),
      shoppingcart: computed(() => vuexStore.getters.getWarenkorbPos),
      loggedIn: computed(() => vuexStore.getters.isLoggedIn),
      shopnameSet: computed(() => route.params.shopname as string | undefined !== undefined)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
